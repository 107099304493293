import { createRouter, createWebHistory } from 'vue-router'
import fullLayout from '../views/common/fullLayout.vue'
import store from "@/stores/store"

const router = createRouter({
  history: createWebHistory(),
  routes: [
  {
    path: '/',
    name: 'Intro',
    redirect: { name: 'Login' },
    meta: {
      title: '모든수산 공급업체'
    }
  },
  {
    path: '/',
    component: fullLayout,
    children: [
      {
        path: '/estimate',
        component: () => import('@/views/Estimate'),
        name: 'estimate',
        meta:{
          needAuth:true
        },
      },{
        path: '/estimate_reg',
        component: () => import('@/views/EstimateRegister'),
        name: 'estimate register',
        meta:{
          needAuth:true
        },
      },{
        path: '/order',
        component: () => import('@/views/Order'),
        name: 'order',
        meta:{
          needAuth:true
        },
      },

  ]},
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/common/Login'),
  },
    {
      path: '/find_id',
      component: () => import('@/views/common/findId'),
      name: 'find id',
    },
    {
      path: '/find_pw',
      component: () => import('@/views/common/findPw'),
      name: 'find pw',
    },
    {
      path: '/entry',
      component: () => import('@/views/common/entry'),
      name: 'entry',
    },
]
})

router.beforeEach((to, from, next)=>{
  if(to.meta.needAuth) {
    // if(store.) 로그인 상태인지 확인 
    //아니면 로그인 화면으로 튕기기 
    if (!store.getters.getSessionToken) {
      alert("로그인이 필요합니다.\n로그인페이지로 이동합니다.")
      router.push({name: 'Login'});
    }
  }
  next()
  document.body.scrollTo(0,0)
})

export default router;
