<template>
  <transition name="modal">
    <div class="modal" @click="$emit('closeModal')">
      <div class="modal_wrap " style="width:100%; max-width:860px;">
        <div class="modal_header">
          <h2>개인정보처리방침</h2>
          <button class="btn_close_modal" @click="$emit('closeModal')">닫기</button>
        </div>
        <div class="modal_body modal_body_scroll">
          <div class="agreement">
            <p>금전푸드는 고객님의 개인정보를 중요시하며, "개인정보보호법"을 준수하고 있습니다.</p>
            <p>회사는 개인정보처리방침을 통하여 고객님께서 제공하시는 개인정보가 어떠한 용도와 방식으로 이용되고 있으며, 개인정보보호를 위해 어떠한 조치가 취해지고 있는지 알려드립니다.</p>
            <p>회사는 개인정보처리방침을 개정하는 경우 웹사이트 공지사항(또는 개별공지)을 통하여 공지할 것입니다.</p>

            <h3>■ 개인정보의 수집항목 및 이용목적</h3>
            <ol>
              <p>고객은 사이트 회원 가입 시 이용약관, 개인정보 수집 및 이용 동의에 대하여 [동의함] 버튼을 클릭하면 해당 개인정보 수집 및 이용에 대해 동의한 것으로 봅니다</p>
              <li>1) 회사는 아래와 같은 개인정보를 수집하고 있습니다.
                <div class="table">
                  <table>
                    <tr>
                      <th>수집시기</th>
                      <th>수집항목</th>
                      <th>이용목적</th>
                      <th>보유기간</th>
                    </tr>
                    <tr>
                      <td>가입시</td>
                      <td>
                        [필수] 아이디, 비밀번호, 비밀번호확인 질문, 비밀번호확인 답변, 이름, 휴대전화, 이메일, 생년월일
                        [선택] 일반전화, 주소
                      </td>
                      <td>회원가입, 이용자 식별, 공지사항 전달</td>
                      <td rowspan="4">회원탈퇴시 또는 법정 의무 보유기간</td>
                    </tr>
                    <tr>
                      <td>간편가입시(카카오)</td>
                      <td>[필수] 생일, 출생 연도, 프로필 정보(닉네임/프로필 사진), 카카오톡 채널 추가 상태 및 내역 [선택] 카카오계정(이메일), 성별, 연령대</td>
                      <td>SNS 및 제3자 계정을 연계하여 간편로그인 사용</td>

                    </tr>
                    <tr>
                      <td>주문시</td>
                      <td>[필수] 주문 정보 (성명, 휴대전화, 이메일), 배송 정보(성명, 주소, 휴대전화), 결제 정보 (성명, 카드 정보, 은행계좌정보 등)<br>
                        [선택] 주문 정보 (일반전화), 배송 정보 (일반전화, 배송 메시지)
                      </td>
                      <td>주문상품의 결제, 배송 및 서비스 제공을 위한 사용자 정보 확인</td>

                    </tr>
                    <tr>
                      <td>교환/반품</td>
                      <td>[필수] 주문정보, 주문번호, 주문자명/아이디, 연락처<br>
                        [선택] 환불계좌정보(은행명, 예금주, 계좌번호)
                      </td>
                      <td>교환/반품 문의에 대한 처리 확인용</td>

                    </tr>
                  </table>
                </div>
              </li>
              <li>2) 서비스 이용과정에서 아래와 같은 정보들이 생성되어 수집될 수 있습니다.
                <p>-서비스 이용기록, 접속로그, 쿠키, 접속IP 정보, 결제기록, 이용정지기록, 불량이용기록</p>
              </li>
              <li>3) 마케팅/광고에의 활용
                <p>-회원의 서비스 이용에 대한 통계 및 이벤트, 상품에 대한 판매소식등 광고성 정보를 전달 하고 있습니다.</p>
              </li>
            </ol>

            <h3>■ 개인정보의 보유 및 이용기간</h3>
            <p>원칙적으로, 개인정보 수집 및 이용목적이 달성된 후에는 해당 정보를 지체 없이 파기합니다. 단, 다음의 정보에 대해서는 아래의 이유로 명시한 기간 동안 보존합니다.</p>
            <ol>
              <li>1) 관련 법령에 의한 정보 보유 사유<br>
                <div class="table">
                  <table>
                    <tr>
                      <th>관련 법류</th><th>보유항목</th><th>보유기간</th>
                    </tr>
                    <tr>
                      <td rowspan="3">전자상거래등에서의 소비자보호에 관한 법률</td><td>계약 또는 창약철회 등에 관한 기록</td><td>5년</td>
                    </tr>
                    <tr>
                      <td>대금 결제 및 재화 등의 공급에 관한 기록</td><td>5년</td>
                    </tr>
                    <tr>
                      <td>소비자 불만 또는 분쟁처리에 관한 기록</td><td>5년</td>
                    </tr>
                    <tr>
                      <td>통신비밀보호법</td><td>로그 기록</td><td>3개월</td>
                    </tr>
                  </table>
                </div>
              </li>
            </ol>

            <h3>■ 개인정보의 안전성 확보조치에 관한 사항</h3>
            <p>회사는 개인정보의 안정성 확보를 위해 다음과 같은 조치를 취하고 있습니다.</p>
            <ul>
              <li>관리적 조치: 내부관리계획 수립∙시행, 정기적 직원 교육 등</li>
              <li>기술적 조치: 개인정보처리시스템 등의 접근권한 관리, 접근통제시스템 설치. 고유식별정보 등의 암호화, 보안프로그램 설치</li>
              <li>물리적 조치: 전산실, 자료보관실 등의 접근 통제</li>
            </ul>

            <h3>■ 개인정보의 파기절차 및 방법</h3>
            <p>회사는 원칙적으로 개인정보 수집 및 이용목적이 달성된 후에는 해당 정보를 지체없이 파기합니다. 파기절차 및 방법은 다음과 같습니다.</p>
            <ol class="disc">
              <li>파기절차
                <p>- 회원님이 회원가입 등을 위해 입력하신 정보는 목적이 달성된 후 별도의 DB로 옮겨져(종이의 경우 별도의 서류함) 내부 방침 및 기타 관련 법령에 의한 정보보호 사유에 따라(보유 및 이용기간 참조) 일정 기간 저장된 후 파기되어집니다.</p>
                <p>별도 DB로 옮겨진 개인정보는 법률에 의한 경우가 아니고서는 보유되어지는 이외의 다른 목적으로 이용되지 않습니다.</p>
              </li>
              <li> 파기방법
                <p>전자적 파일형태로 저장된 개인정보는 기록을 재생할 수 없는 기술적 방법을 사용하여 삭제합니다.</p>
                <p>종이에 출력된 개인정보는 분쇄기로 분쇄하거나 소각을 통하여 파기합니다.</p>
              </li>
            </ol>

            <h3>■ 개인정보 제공</h3>
            <p>회사는 이용자의 개인정보를 원칙적으로 외부에 제공하지 않습니다. 다만, 아래의 경우에는 예외로 합니다.</p>
            <ol class="disc">
              <li>이용자들이 사전에 동의한 경우</li>
              <li>법령의 규정에 의거하거나, 수사 목적으로 법령에 정해진 절차와 방법에 따라 수사기관의 요구가 있는 경우</li>
            </ol>

            <h3>■ 수집한 개인정보의 위탁</h3>
            <p>회사는 고객서비스 관리 및 민원사항에 대한 등 원활한 업무 수행을 위하여 아래와 같이 개인정보 처리 업무를 위탁하여 운영하고 있습니다.</p>
            <p>또한 위탁계약 시 개인정보보호의 안전을 기하기 위하여 개인정보보호 관련 법규의 준수, 개인정보에 관한 제3자 공급 금지, 사고시의 책임부담 등을 명확히 규정하고 있습니다. 동 업체가 변경될 경우, 변경된 업체 명을 공지사항 내지 개인정보처리방침 화면을 통해 고지하겠습니다.</p>
            <div class="table">
              <table>
                <tr>
                  <th>수탁업체</th><th>위탁업무내용</th><th>보유 및 이용기간</th>
                </tr>
                <tr>
                  <td>엔에이치엔클라우드 주식회사</td><td>카카오톡 알림톡 발송업무</td><td>계약 종료시</td>
                </tr>
                <tr>
                  <td>주식회사 리브리오</td><td>문자메시지 발송업무</td><td>계약 종료시</td>
                </tr>
              </table>
            </div>

            <h3>■ 이용자 및 법정대리인의 권리와 그 행사방법</h3>
            <ol class="disc">
              <li>이용자는 언제든지 등록되어 있는 자신의 개인정보를 조회하거나 수정할 수 있으며 가입해지를 요청할 수도 있습니다.</li>
              <li>이용자의 개인정보 조회,수정을 위해서는 "회원정보수정"을 가입해지(동의철회)를 위해서는 “회원탈퇴”를 클릭 한후 직접 열람, 정정 또는 탈퇴가 가능합니다. 혹은 개인정보보호책임자에게 서면, 전화 또는 이메일로 연락하시면 지체없이 조치하겠습니다.</li>
              <li>귀하가 개인정보의 오류에 대한 정정을 요청하신 경우에는 정정을 완료하기 전까 지 당해 개인정보를 이용 또는 제공하지 않습니다. 또한 잘못된 개인정보를 제3자 에게 이미 제공한 경우에는 정정 처리결과를 제3자에게 지체없이 통지하여 정정이 이루어지도록 하겠습니다.</li>
              <li>금전푸드는 이용자 혹은 법정 대리인의 요청에 의해 해지 또는 삭제된 개인정보는 금전푸드가 수집하는 "개인정보의 보유 및 이용기간"에 명시된 바에 따라 처리하고 그 외의 용도로 열람 또는 이용할 수 없도록 처리하고 있습니다.</li>
            </ol>

            <h3>■ 개인정보 자동수집 장치의 설치, 운영 및 그 거부에 관한 사항</h3>
            <p>회사는 귀하의 정보를 수시로 저장하고 찾아내는 ‘쿠키(cookie)’ 등을 운용합니다. 쿠키란 금전푸드의 웹사이트를 운영하는데 이용되는 서버가 귀하의 브라우저에 보내는 아주 작은 텍스트 파일로서 귀하의 컴퓨터 하드디스크에 저장됩니다.</p>
            <p>회사는 다음과 같은 목적을 위해 쿠키를 사용합니다.</p>
            <ol class="disc">
              <li>쿠키 등 사용 목적
                <p>회원과 비회원의 접속 빈도나 방문 시간 등을 분석, 이용자의 취향과 관심분야를 파악 및 자취 추적, 각종 이벤트 참여 정도 및 방문 회수 파악 등을 통한 타겟 마케팅 및 개인 맞춤 서비스 제공</p>
                <p>귀하는 쿠키 설치에 대한 선택권을 가지고 있습니다. 따라서, 귀하는 웹브라우저에서 옵션을 설정함으로써 모든 쿠키를 허용하거나, 쿠키가 저장될 때마다 확인을 거치거나, 아니면 모든 쿠키의 저장을 거부할 수도 있습니다.</p>
              </li>
              <li>쿠키 설정 거부 방법
                <p>- 쿠키 설정을 거부하는 방법으로는 회원님이 사용하시는 웹 브라우저의 옵션을 선택함으로써 모든 쿠키를 허용하거나 쿠키를 저장할 때마다 확인을 거치거나, 모든 쿠키의 저장을 거부할 수 있습니다.</p>
                <p>- 설정방법 예<br>
                  인터넷 익스플로어 : 웹 브라우저 상단의 도구 > 인터넷 옵션 > 개인정보 > 고급<br>
                  Chrome : 웹 브라우저 상단의 도구 > 설정 > 고급 > 개인정보 및 보안 > 콘텐츠 설정 > 쿠키</p>
                <p>단, 귀하께서 쿠키 설치를 거부하였을 경우 서비스 제공에 어려움이 있을 수 있습니다.</p>
              </li>
            </ol>


            <h3>■ 행태정보의 수집, 이용, 제공 및 거부 등에 관한 사항</h3>
            <p>회사는 서비스 이용과정에서 고객분석 등 이 사이트의 이용 분석에 활용하기 위해 온라인 행태정보를 수집·이용하고 있습니다.</p>
            <p>행태정보란 웹 사이트 방문 이력, 앱 사용 이력, 구매 및 검색 이력 등 이용자의 관심, 흥미, 기호 및 성향 등을 파악하고 분석할 수 있는 온라인상의 이용자 활동정보를 말합니다.</p>

            <p>회사는 다음과 같이 행태정보를 수집합니다.</p>
            <div class="table">
              <table>
                <tr>
                  <th>수집하는 행태정보의 항목</th><th>행태정보 수집 방법</th><th>행태정보 수집 목적</th><th>보유, 이용기간 및 이후 정보 처리 방법</th>
                </tr>
                <tr>
                  <td>웹/앱 서비스 방문 기록, 검색·클릭 등 사용기록</td><td>웹사이트 및 앱 내에서 행해지는 고객의 주요 행동에 대한 로그 수집</td><td>이용자의 관심, 성향에 기반한 개인 맞춤형 상품추천서비스를 제공</td>
                  <td>수집일로부터 최대 3년간 보유 이용되며, 이용기간 종료시 행태정보는 지체없이 삭제</td>
                </tr>
              </table>
            </div>
            <p>회사는 온라인 맞춤형 광고 등에 필요한 최소한의 행태정보만을 수집하며, 사상, 신념, 가족 및 친인척관계, 학력·병력, 기타 사회활동 경력 등 개인의 권리·이익이나 사생활을 뚜렷하게 침해할 우려가 있는 민감한 행태정보를 수집하지 않습니다.</p>
            <p>회사는 모바일 앱에서 온라인 맞춤형 광고를 위하여 광고식별자를 수집·이용합니다. 이용자는 모바일 단말기의 설정 변경을 통해 앱의 맞춤형 광고를 차단·허용할 수 있습니다.</p>

            <p>이용자는 웹브라우저의 쿠키 설정 변경 등을 통해 온라인 맞춤형 광고를 일괄적으로 차단·허용할 수 있습니다. 다만, 쿠키 설정 변경은 웹사이트 자동로그인 등 일부 서비스의 이용에 영향을 미칠 수 있습니다.</p>

            <ol>
              <li>웹브라우저를 통한 맞춤형 광고 차단/허용
                <p>(1) 인터넷 익스플로러(Windows 10용 Internet Explorer 11)<br>
                  - Internet Explorer에서 도구 버튼을 선택한 다음 인터넷 옵션을 선택<br>
                  - 개인 정보 탭을 선택하고 설정에서 고급을 선택한 다음 쿠키의 차단 또는 허용을 선택
                </p>
                <p>(2) Microsoft Edge<br>
                  - Edge에서 오른쪽 상단 ‘…’ 표시를 클릭한 후, 설정을 클릭합니다.<br>
                  - 설정 페이지 좌측의 ‘개인정보, 검색 및 서비스’를 클릭 후 「추적방지」 섹션에서 ‘추적방지’ 여부 및 수준을 선택합니다.<br>
                  - ‘InPrivate를 검색할 때 항상 ""엄격"" 추적 방지 사용’ 여부를 선택합니다.<br>
                  - 아래 「개인정보」 섹션에서 ‘추적 안함 요청보내기’ 여부를 선택합니다.
                </p>
                <p>(3) 크롬 브라우저<br>
                  - Chrome에서 오른쪽 상단 ‘⋮’ 표시(chrome 맞춤설정 및 제어)를 클릭한 후, 설정 표시를 클릭합니다.<br>
                  - 설정 페이지 하단에 ‘고급 설정 표시’를 클릭하고 「개인정보」 섹션에서 콘텐츠 설정을 클릭합니다.<br>
                  - 쿠키 섹션에서 ‘타사 쿠키 및 사이트 데이터 차단’의 체크박스를 선택합니다.<br><br>

                  이용자는 아래의 연락처로 행태정보와 관련하여 궁금한 사항과 거부권 행사, 피해 신고 접수 등을 문의할 수 있습니다.</p>
              </li>

              <li>개인정보보호 담당부서
                <p>부서명 : CS팀</p>
                <p>연락처 : 1688-1360</p>
              </li>
            </ol>

            <h3>■ 14세 미만 아동의 가입제한</h3>
            <p>회사는 법적대리인의 동의가 필요한 만14세 미만 아동의 회원가입은 받고 있지 않습니다.</p>

            <h3>■ 개인정보 보호책임자</h3>
            <p>회사는 고객의 개인정보를 보호하고 개인정보와 관련한 불만, 개인정보의 열람청구를 처리하기 위하여 아래와 같이 관련 부서 및 개인정보보호책임자를 지정하고 있습니다.</p>
            <ol class="disc">
              <li>개인정보보호책임자 성명 : 성동현 이사
                <p>- 전화번호 : 031-552-3007</p>
                <p>- 이메일 : tjdehd11@naver.com</p>

                <p>귀하께서는 회사의 서비스를 이용하시며 발생하는 모든 개인정보보호 관련 민원을 개인정보보호책임자 혹은 담당부서로 신고하실 수 있습니다. 회사는 이용자들의 신고사항에 대해 신속하게 충분한 답변을 드릴 것입니다.</p>
              </li>
            </ol>

            <h3>■ 개인정보 열람청구</h3>
            <p>귀하께서는 「개인정보 보호법」 제35조에 따른 개인정보의 열람 청구를 아래의 부서에 할 수 있습니다.</p>
            <p>회사는 귀하의 개인정보 열람청구가 신속하게 처리되도록 노력하겠습니다.</p>

            <ol class="disc">
              <li>개인정보 열람청구 접수·처리 부서</li>
              <p>부서명 : CS팀</p>
              <p>연락처 : 1688-1360</p>
            </ol>

            <h3>■ 권익침해 구제방법</h3>
            <p>기타 개인정보침해에 대한 신고나 상담이 필요하신 경우에는 아래 기관에 문의하시기 바랍니다.</p>
            <ol>
              <li>개인분쟁조정위원회 1833-6972 (https://kopico.go.kr)</li>
              <li>한국인터넷 진흥원 개인정보 침해신고센터 (국번없이) 118 (https://privacy.kisa.or.kr)</li>
              <li>대검찰청 사이버수사과 (국번없이) 1301 (https://spo.go.kr)</li>
              <li>경찰청 사이버범죄 신고시스템(ECRM) : (국번없이) 182 (https://ecrm.cyber.go.kr)</li>
            </ol>

            <h3>■ 고지의 의무</h3>
            <p>본 개인정보처리방침은 법령 정책 또는 보안기술의 변경에 따라 중요한 내용의 추가 삭제 및 수정이 있을 시에는 변경되는 개인정보처리방침을 시행하기 전, 개정 7일 이전에 홈페이지의 ‘공지’란을 통해 고지합니다.<br></p>
            <p>이 개인정보 처리방침의 공고 및 시행일자는 아래와 같습니다.<br><br></p>

            <p>- 공고일자 : 2023년 9월 21일</p>
            <p>- 시행일자 : 2023년 9월 29일</p>

          </div>
        </div>
      </div>
    </div>
  </transition>
</template>
<script setup>

</script>
