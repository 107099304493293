<template>
  <header>
    <div class="wrapper">
      <RouterLink to="/estimate"><h1 class="logo">모든수산</h1></RouterLink>
      <div class="user">{{ store.getters.getMyInfo?.brandName || "공급업체" }} 
        <button class="btn_logout" @click="logout">로그아웃</button></div>
    </div>
  </header>
</template>

<script setup>
import { useStore } from "vuex"
import { useRoute, useRouter } from 'vue-router'

const store = useStore()
const router = useRouter()

function logout() {
  store.dispatch('initState')
  router.replace({name:'Login'})

}


</script>
