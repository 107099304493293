<template>
  <footer>
    <div class="footer_menu">
      <div class="wrapper">
        <a href="https://company.modunsusan.com/#company" target='_blank'>회사소개</a>
        <button @click="goService()">약관 및 정책</button>
        <button @click="goPrivacy()">개인정보처리방침</button>
        <a href="https://modunsusan.com/entry" target='_blank'>입점신청</a>
      </div>
    </div>
    <div class="wrapper">
      <div class="company_info">
        <h2 class="logo_footer">금전푸드</h2>
        <p>경기도 용인시 수지구 동천로437번길 8, 지층 (동천동)</p>
        <p><span>상호 : 금전푸드</span> <span>대표자: 김진욱</span> <span>사업자등록번호 : 848-81-03301</span> <span>통신판매업신고 : 2023-용인수지-2823</span></p>
        <p class="copyright">Copyright © 금전푸드 Corp. All Rights Reserved.</p>
      </div>
      <div class="support">
        <div class="cs_title">고객센터 <span class="cs_num">031-552-3007</span></div>
        <p>평일 10:00 ~ 17:00 / 토,일요일, 공휴일 휴무</p>
        <p>점심시간 12:00 ~ 13:00</p>
        <button class="btn_kakao"><i></i>카카오톡 1:1문의</button>
      </div>

    </div>
  </footer>
  <Service v-if="isAgreeService" @closeModal="isAgreeService = false;"/>
  <Privacy v-if="isAgreePrivacy" @closeModal="isAgreePrivacy = false;"/>
</template>

<script setup>
import {ref} from "vue";

import Service from "@/views/modal/modalService";
import Privacy from "@/views/modal/modalPrivacy";

const isAgreeService = ref(false);
const isAgreePrivacy = ref(false);

function goService() {
  isAgreeService.value = true;
}
function goPrivacy() {
  isAgreePrivacy.value = true;
}

</script>