
import { createApp } from 'vue'
import App from './App.vue'
import { createStore } from 'vuex';
import router from './router/router'
import './assets/css/common.css'

import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
import store from "@/stores/store";
import axios from "axios";

const http = axios.create({
    baseURL: '', // url = base url + request url
    timeout: 60000,
});

http.interceptors.request.use((config) => {
    let token = store.getters.getSessionToken;
    if(!token) {
        const content = localStorage.getItem('am-vender-session');
        if (content) {
            token = content;
            if (token) {
                store.dispatch("setSessionToken", content).then();
            }
        }
    }
    if (token) {
        if (!config.headers) {
            config.headers = {};
        }
        config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
})
let isTokenRefreshing = false;
let refreshSubscribers = [];

const onTokenRefreshed = (accessToken) => {
    refreshSubscribers.map((callback) => callback(accessToken));
};

const addRefreshSubscriber = (callback) => {
    refreshSubscribers.push(callback);
};
http.interceptors.response.use((response) => {
    return response;
}, async (error) => {
    const {
        config,
        response: {status},
    } = error;
    const originalRequest = config;
    if (status === 403) {
        if (!isTokenRefreshing) {
            // isTokenRefreshing이 false인 경우에만 token refresh 요청
            isTokenRefreshing = true;
            const {data} = await http.post(
                `/api/vender/refresh`, // token refresh api
            );
            // 새로운 토큰 저장
            const {
                accessToken
            } = data;
            isTokenRefreshing = false;
            http.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
            store.commit({type: 'setSessionToken', token: accessToken})
            // 새로운 토큰으로 지연되었던 요청 진행
            onTokenRefreshed(accessToken);
        }
        // token이 재발급 되는 동안의 요청은 refreshSubscribers에 저장
        const retryOriginalRequest = new Promise((resolve) => {
            addRefreshSubscriber((accessToken) => {
                originalRequest.headers.Authorization = "Bearer " + accessToken;
                resolve(http(originalRequest));
            });
        });
        return retryOriginalRequest;
    }
    if(status === 401) {
        alert("로그인이 필요합니다.\n로그인페이지로 이동합니다.")
        store.dispatch('initState')
        await router.push({name: 'Login'});
    }
    return Promise.reject(error);
})
const app = createApp(App)

app.use(createStore)
app.use(router)
app.use(store)
app.mount('#app')
app.use('VueDatePicker', VueDatePicker);
app.config.globalProperties.$axios=http;
app.config.globalProperties.$store=store;



