<template>
  <div id="navi" class="nav">
    <h2>공급업체 포털</h2>
    <ul>
      <li v-for="( menu, idx ) in naviItem" :key="idx" :class="{ active: activeMenu(menu) }">
        <template v-if="menu.children">
          <a class="depth1" :title="menu.name">{{ menu.name }}</a>
          <ul class="depth2">
            <li v-for="{ name, url, idx } in menu.children" :key="idx"
                :class="{ active:  route.fullPath === url}">
              <a :href="url" :title="name" active-class="active">{{ name }}</a>
            </li>
          </ul>
        </template>
        <template v-else>
          <a class="depth1" :href="menu.url" :title="menu.name">{{ menu.name }}</a>
        </template>
      </li>
    </ul>
  </div>
</template>

<script setup>

import {ref} from "vue";
import {useRoute} from "vue-router";

const route = useRoute();

const naviItem = ref([
  {name: '견적내역', url: "/estimate"},
  {name: '견적 등록', url: "/estimate_reg"},
  {name: '발주내역/서류등록', url: "/order"}
]);

function activeMenu(menu) {
  let result = false;
  if (menu.children) {
    for (let item of menu.children) {
      if (item.url === route.fullPath) {
        result = true;
      }
    }
  } else if (menu.url === route.fullPath) {
    result = true;
  }
  return result;
}

</script>
<style>
.active a {
  color : white;
}
</style>